import React from 'react';
import { TitleAndSubtitle } from '../shared/titleAndSubtitle';
import './faqContact.scss';
import getTranslations from '../../utils/getTranslations';
import email from '../../../assets/atom/icon/48px/email.svg';
import phone from '../../../assets/atom/icon/48px/phone.svg';

export const FaqContact: React.FC = () => {
  const {
    didntFindSomething,
    pleaseContactOurSupport,
    phoneDesc,
    emailDesc
  } = getTranslations()!.faq!;

  return (
    <div id='faq-contact'>
      <div id='text'>
        <TitleAndSubtitle
          title={didntFindSomething!}
          subtitle={pleaseContactOurSupport!}
        />
      </div>
      <div className='columns main-padding' id='contact-boxes'>
        <div className='column is-5 is-offset-1'>
          <div className='contact-box' id='email'>
            <img src={email} />
            <a className='title' href='mailto:support@beefit.io'>
              support@beefit.io
            </a>
            <span className='subtitle'>{emailDesc}</span>
          </div>
        </div>
        <div className='column is-5'>
          <div className='contact-box' id='phone'>
            <img src={phone} />
            <a className='title' href='tel:+45-42-61-10-96'>
              +45 42 61 10 96
            </a>
            <span className='subtitle'>{phoneDesc}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
