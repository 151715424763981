import React from 'react';
import './titleAndSubtitle.scss';

export const TitleAndSubtitle: React.FC<{
  title: string;
  subtitle: string;
}> = props => {
  return (
    <div className='column is-6 is-offset-3'>
      <h2 id='title-and-subtitle-title'>{props.title}</h2>
      <span id='title-and-subtitle-subtitle'>{props.subtitle}</span>
    </div>
  );
};
