import React from 'react';
import './faqOverview.scss';
import { IFaqArticle } from './faqPage';
import { ArticleButton } from './articleButton';
import LocalizedLink from '../shared/localizedLink';
import { FaqContact } from './faqContact';
import getTranslations from '../../utils/getTranslations';

export interface ISection {
  title: string;
  slug: string;
}

export const FaqOverview: React.FC<{
  articles: IFaqArticle[];
  sections: ISection[];
  currentLocale: string;
}> = ({ articles, sections, currentLocale }) => {
  const { viewAll } = getTranslations().faq!;

  const articlesByLocale = articles.filter(
    article => article.language.slice(0, 2) === currentLocale
  )!;
  const sectionsByLocale = sections.filter(section =>
    articlesByLocale.find(art => art.section === section.title)
  );

  const renderSections = () => {
    return sectionsByLocale.map(section => {
      const articleButtons = articlesByLocale
        .filter(article => article.section === section.title)
        .map(article => (
          <div className='article-button' key={article._id}>
            <ArticleButton title={article.title} slug={article.slug} />
          </div>
        ))
        .slice(0, 4);

      return (
        <div key={section.slug} className='column is-6 faq-section'>
          <h3>{section.title}</h3>
          {articleButtons}
          <LocalizedLink to={section.slug}>{viewAll}</LocalizedLink>
        </div>
      );
    });
  };

  return (
    <div id='faq-overview' className='main-padding'>
      <div className='columns is-multiline'>{renderSections()}</div>
      <FaqContact />
    </div>
  );
};
