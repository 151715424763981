import React from 'react';
import './articleButton.scss';
import LocalizedLink from '../shared/localizedLink';
import { document } from 'browser-monads';

export const ArticleButton: React.FC<{ title: string; slug: string }> = ({ title, slug }) => {
  return (
    <LocalizedLink to={slug}>
      <div className='article-box'>
        <span>{title}</span>
      </div>
    </LocalizedLink>
  );
};
