import * as React from 'react';
import { FaqSearch } from './faqSearch';
import { FaqOverview, ISection } from './faqOverview';

export interface IFaqArticle {
  _id: string;
  title: string;
  section: string;
  keywords: string[];
  related_articles?: IRelatedArticle[];
  content: string;
  slug: string;
  source_article_id: string;
  language: string;
}

export interface IRelatedArticle {
  _id: string;
  title: string;
  slug: string;
}

const FaqPage: React.FC<{
  pageContext: {
    articles: IFaqArticle[];
    sections: ISection[];
    locale: string;
  };
}> = ({ pageContext: { articles, sections, locale } }) => {
  return (
    <div>
      <FaqSearch articles={articles} locale={locale} />
      <FaqOverview
        articles={articles}
        sections={sections}
        currentLocale={locale}
      />
    </div>
  );
};

export default FaqPage;
