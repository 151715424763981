import React from 'react';
import './faqSearch.scss';
import Autosuggest from 'react-autosuggest';
import { IFaqArticle } from './faqPage';
import autoSuggestTheme from './autoSuggest.module.scss';
import getTranslations from '../../utils/getTranslations';
import { navigate } from 'gatsby';
import getLocalizedPath from '../../utils/getLocalizedPath';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export const FaqSearch: React.FC<{
  articles: IFaqArticle[];
  locale: string;
}> = ({ articles, locale }) => {
  const [suggestions, setSuggestions] = React.useState<IFaqArticle[]>([]);
  const [suggestionValue, setSuggestionValue] = React.useState<string>('');
  const [inputLabelClass, setInputLabelClass] = React.useState<string>(
    'search-white'
  );
  const { title, typeYourQuestion } = getTranslations().faq!;

  const FaqAutosuggest = Autosuggest as new () => Autosuggest<IFaqArticle>;

  const getSuggestions = (currValue: string): IFaqArticle[] => {
    const inputValue = currValue.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength <= 1
      ? []
      : articles.filter(article =>
          article.title.toLowerCase().includes(inputValue)
        );
  };

  const renderSuggestion = (suggestion: IFaqArticle) => {
    const matches = match(suggestion.title, suggestionValue);

    const parts = parse(suggestion.title, matches);
    const highlightedText = parts.map((part, i) => {
      const textClass = part.highlight ? 'highlighted-text' : '';
      const space = <span>{` `}</span>;
      let startSpace = false;
      let endSpace = false;
      if (part.text[0] === ' ') {
        startSpace = true;
      }
      if (part.text[part.text.length - 1]) {
        endSpace = true;
      }

      return (
        <span key={i} className={textClass}>
          {startSpace && space}
          {part.text}
          {endSpace && space}
        </span>
      );
    });

    return <div className='suggestion-text-container'>{highlightedText}</div>;
  };

  const getSuggestionValue = (suggestion: IFaqArticle) => suggestion.title;

  const onSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = (inputProps: any): JSX.Element => {
    const { onBlur, ...restInputProps } = inputProps;
    return (
      <div id='search-input'>
        <label className={inputLabelClass}>
          <input
            {...restInputProps}
            onClick={() => setInputLabelClass('search-blue')}
          />
        </label>
      </div>
    );
  };

  const onInputChange = (
    event: React.FormEvent<any>,
    { newValue }: Autosuggest.ChangeEvent
  ): void => {
    setSuggestionValue(newValue);
  };

  const goToSuggestion = (
    event: React.FormEvent<any>,
    data: Autosuggest.SuggestionSelectedEventData<IFaqArticle>
  ) => {
    navigate(getLocalizedPath(locale, data.suggestion.slug));
  };

  return (
    <div id='faq-search' className='main-padding rounded-bottom-corner'>
      <div className='has-text-centered '>
        <h1>{title}</h1>
      </div>
      <div>
        <div className='column is-10 is-offset-1' id='autosuggest'>
          <FaqAutosuggest
            theme={autoSuggestTheme}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderInputComponent={renderInputComponent}
            onSuggestionSelected={goToSuggestion}
            inputProps={{
              placeholder: `${typeYourQuestion!}...`,
              value: suggestionValue,
              onChange: (e, changeEvent) => onInputChange(e, changeEvent)
            }}
          />
        </div>
      </div>
    </div>
  );
};
